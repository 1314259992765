import React from 'react';
import {getUrlForPhoto} from '../../lib/cloudinary';
import {trackEvent} from '../../lib/track';
import ReactPhotoGallery from 'react-photo-gallery';
import GalleryPhoto from '../GalleryPhoto';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import styles from './styles.scss';

export default class Gallery extends React.Component {
  static defaultProps = {
    columns: columns,
    margin: 10,
  };

  state = {
    index: 0,
    isOpen: false,
  };

  render() {
    if (this.props.album == null) return null;
    const photos = this.props.album.photos.map(dataToPhoto);
    const sources = this.props.album.photos.map(p =>
      getUrlForPhoto({id: p.cloudinaryId, width: global.innerWidth, height: global.innerHeight})
    );

    return (
      <div className={styles.root}>
        <ReactPhotoGallery
          direction="column"
          margin={this.props.margin}
          columns={this.props.columns}
          ImageComponent={GalleryPhoto}
          photos={this.props.album.photos.map(dataToPhoto)}
          onClick={this.handleClick}
        />
        {this.state.isOpen && (
          <Lightbox
            mainSrc={sources[this.state.index]}
            nextSrc={sources[(this.state.index + 1) % sources.length]}
            prevSrc={sources[(this.state.index + sources.length - 1) % sources.length]}
            onCloseRequest={this.handleCloseRequest}
            onMovePrevRequest={this.handleMovePrevRequest}
            onMoveNextRequest={this.handleMoveNextRequest}
            animationDisabled
            imagePadding={20}
            enableZoom={false}
          />
        )}
      </div>
    );
  }

  handleCloseRequest = () => {
    this.setState({isOpen: false});
  };

  handleMovePrevRequest = () => {
    this.setState(state => ({
      index: (state.index + this.props.album.photos.length - 1) % this.props.album.photos.length,
    }));
  };

  handleMoveNextRequest = () => {
    this.setState(state => ({
      index: (state.index + 1) % this.props.album.photos.length,
    }));
  };

  handleClick = (event, data) => {
    trackEvent('view_photo', {category: 'engagement', value: this.props.album.photos[data.index].cloudinaryId});
    this.setState({isOpen: true, index: data.index});
  };
}

function columns(width = 1000) {
  return Math.max(1, Math.round(width / 350));
}

function dataToPhoto(photo, index) {
  return {
    id: photo.cloudinaryId,
    key: index,
    src: getUrlForPhoto({id: photo.cloudinaryId}),
    width: photo.width,
    height: photo.height,
  };
}
