const PRESETS = [250, 500, 750, 1000, 1500, 2000];
const pixelRatio = global.devicePixelRatio || 1;

export function getUrlForPhoto({width = 500, height = 500, id}) {
  const size = Math.max(width, height) * pixelRatio;
  const preset = PRESETS.find(s => s >= size) || PRESETS[PRESETS.length - 1];
  return `https://res.cloudinary.com/levibuzolic/image/upload/t_miralevina-${preset}/v1/${id}`;
}

export function getPublicId(url) {
  // https://res.cloudinary.com/levibuzolic/image/upload/sample.jpg
  // https://res.cloudinary.com/levibuzolic/image/upload/v1541939580/sample.jpg
  // http://res.cloudinary.com/levibuzolic/image/upload/t_miralevina-thumbnail/sample.jpg
  // https://res.cloudinary.com/levibuzolic/image/upload/t_miralevina-250/v1/miralevina.com/20180119-093156-0014
  // https://res.cloudinary.com/levibuzolic/image/upload/t_miralevina-250/v1/sample
}
