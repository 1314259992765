import React from 'react';
import LazyLoad from 'react-lazyload';
import AdminContext from '../../lib/AdminContext';
import {getUrlForPhoto} from '../../lib/cloudinary';
import styles from './styles.scss';

const imgWithClick = {cursor: 'pointer'};

export default class Photo extends React.Component {
  state = {
    loaded: false,
  };

  static contextType = AdminContext;

  render() {
    const {onClick, photo, margin, direction, top, left} = this.props;

    const imgStyle = {
      margin,
      width: Math.round(photo.width),
      height: Math.round(photo.height),
    };

    if (direction === 'column') {
      imgStyle.position = 'absolute';
      imgStyle.left = Math.round(left);
      imgStyle.top = Math.round(top);
    }

    let img = (
      <img
        key={photo.id}
        width={Math.round(photo.width)}
        height={Math.round(photo.height)}
        src={getUrlForPhoto(photo)}
        className={this.state.loaded ? styles.imgLoaded : styles.img}
        alt=""
        onContextMenu={this.handleContextMenu}
        onLoad={this.handleLoad}
      />
    );

    if (!this.context) {
      img = <LazyLoad height={Math.round(photo.height)} once offset={100} children={img} />;
    }

    return (
      <div
        className={styles.root}
        style={{
          ...imgStyle,
          ...(onClick ? imgWithClick : {}),
        }}
        onClick={onClick ? this.handleClick : null}
      >
        {img}
      </div>
    );
  }

  handleLoad = () => {
    this.setState({loaded: true});
  };

  handleClick = event => {
    this.props.onClick(event, {
      photo: this.props.photo,
      index: this.props.index,
    });
  };

  handleContextMenu = event => {
    event.preventDefault();
  };
}
